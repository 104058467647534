import { useTranslation } from "react-i18next"
import { groupDetailPropTypes } from "../../PropTypes/ScanningScreenPropType"

interface PropTypes {
    violation: groupDetailPropTypes
}

const DisabilitiesAffected = (props: PropTypes) => {

    const { violation } = props;

    const { t } = useTranslation();

    return (
        <>
            <div className='disabilities-affected'>
                <span role="heading" className="header">
                {t("Disabilities Affected")}
                </span>
                <ul className='disabilities-list'>
                    {violation.cognitive_disabilities ? <li className='cognitive_disabilities'> {t("Cognitive & Learning")} </li> : <></ >}
                    {violation.motor_impaired ? <li className='motor_impaired' > {t("Motor Impaired")} </li> : <></ >}
                    {violation.visually_impaired ? <li className='visually_impaired' > {t("Visually Impaired")} </li> : <></ >}
                    {violation.blind ? <li className='blind' > {t("Blind")} </li> : <></ >}
                    {violation.color_blind ? <li className='color_blind' > {t("Color Blind")} </li> : <></ >}
                    {violation.dyslexia ? <li className='dyslexia' > {t("Dyslexia")} </li> : <></ >}
                    {violation.seizure_epileptic ? <li className='seizure_epileptic' > {t("Seizure & Epileptic")} </li> : <></ >}
                    {violation.adhd ? <li className='adhd' > {t("ADHD")} </li> : <></ >}
                    {violation.elderly ? <li className='elderly' > {t("Elderly")} </li> : <></ >}
                </ul>
            </div>
        </>
    )
}

export default DisabilitiesAffected;