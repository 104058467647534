import { useSelector } from 'react-redux';
import screenMockupPlaceholder from '../../Assets/img/placeholder.svg';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';
import { addDefaultImgSrc } from '../../Helpers/addDefaultImgSrc';
import { useEffect, useState } from 'react';




const WebsiteScreen = () => {

    const { screenShot, isLoading, scaningProcessPer, websiteURL } = useSelector((store: StoreProptypes) => store);

    const [blurValue, setBlurValue] = useState(2.5);

    useEffect(() => {
        if (scaningProcessPer) {
            //console.info("Opt ==>", (2.5/scaningProcessPer))
            if (scaningProcessPer === 100) {
                setBlurValue(0);
            } else {
                setBlurValue(blurValue - 0.2);
            }

        }
    }, [scaningProcessPer])

    /* const [websiteURLPeram, setWebsiteURLPeram] = useState<string>(''); */

    /* useEffect(() => {
        if (websiteURL !== null) {
            setWebsiteURLPeram(`https://ada.skynettechnologies.us/trial-subscription?website=${websiteURL.split('/')[0]}`)
        } else {
            setWebsiteURLPeram(`https://ada.skynettechnologies.us/trial-subscription`)
        }
    }, [websiteURL]); */

    return (
        <>
            <div className='screenshot-main-wrap h-100'>
                <div className='screenshot-main border h-100'>
                    <div className='screenshot-loader'>
                        <img onError={addDefaultImgSrc} src={screenShot ? screenShot : screenMockupPlaceholder} style={{ filter: `blur(${blurValue}px)` }} alt=""  />
                        {isLoading ? <span></span> : ''}
                    </div>
                </div>
            </div>
        </>
    )
}

export default WebsiteScreen;