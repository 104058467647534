import { useEffect, useState } from "react"
import supportedLanguages from "../../Helpers/supportedLanguages"
import SupportedLanguagesPropTypes from "../../PropTypes/SupportedLanguagePropTypes";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { SET_LANGUAGE } from "../../Actions/Types";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";

const LanguageSwitcher = () => {

    

    const { i18n } = useTranslation();

    const dispatch = useDispatch();

    const { lang } = useSelector((store: StoreProptypes) => store);

    const [flag, setFlag] = useState(false);

    const [activeLang, setActiveLang] = useState<SupportedLanguagesPropTypes[]>();

    const changeLanguage = (langCode: string) => {
        i18n.changeLanguage(langCode);
        if (langCode !== lang) {
            dispatch({ type: SET_LANGUAGE, payload: langCode });
        }
    }

    const handleActiveLang = () => {
        const currentLang = i18n.language;
        //console.log("currentLang", currentLang);
        const temp = supportedLanguages.filter(language => {
            if (language.slug === currentLang) {
                return true
            }
        });

        if (temp.length) {
            setActiveLang(temp);
        }
    }

    useEffect(() => {
        handleActiveLang();
    }, [i18n.language])

    useEffect(() => {
        if (lang && !flag) {
            setFlag(true);
            changeLanguage(lang);
        }
    }, [lang])


    /* i18n.on('languageChanged', () => {
        
    }); */




    return (
        <div className='language-switch-wrapper'>

            <DropdownButton title={activeLang ? activeLang[0].slug.toUpperCase() : "EN"} align="end">
                {supportedLanguages.map(language => {
                    return <Dropdown.Item as="div" key={language.id} onClick={() => changeLanguage(language.slug)}>
                        <span className="langcode">{language.slug.indexOf("-") > -1 ? language.slug.slice(-2) : language.slug}</span> <span>{language.name}</span>
                    </Dropdown.Item>
                })}
            </DropdownButton>

        </div>
    )
}

export default LanguageSwitcher