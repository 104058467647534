const baseURL = `${process.env.REACT_APP_API_BASE_URL}/api`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

const ScanningProcessAPI = async (websiteURL: string, is_first: number, lang: string): Promise<any> => {

    const url = `${baseURL}/check-page-compliance-new`;

    const data = new FormData();

    data.append("url", websiteURL);
    data.append("is_first", is_first.toString());
    data.append("langcode", lang);

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

const ScannReportAPI = async (
    id: number,
    accessibilityScore: number,
    scannerUserFullName: string,
    scannerUserEmail: string,
    customizeReport: number,
    reportLogoURL: string,
    brandColor: string,
): Promise<any> => {
    const url = `${baseURL}/compliance-report-request`;
    const data = new FormData();

    data.append("id", id.toString());
    data.append("accessibility_score", accessibilityScore.toFixed(0).toString());
    data.append("name", scannerUserFullName);
    data.append("email", scannerUserEmail);
    data.append("customize_report", customizeReport.toString());

    if (customizeReport === 1) {
        data.append("report_logo_url", reportLogoURL);
        data.append("brand_color", brandColor);
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

const GetReportPDFAPI = async (
    id: number,
    scoreImage:string,
    wcagImage:string,
    langcode: string
): Promise<any> => {
    const url = `${baseURL}/generate-compliance-report`;
    const data = new FormData();

    data.append("id", id.toString());
    data.append("langcode", langcode);
    data.append("score_image", scoreImage);
    data.append("level_image", wcagImage);
    

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

interface htmlFileInputProptype {
    id: string,
    file: any
}

const quoteFormAPI = async (
    name: string,
    email: string,
    message: string,
    is_audit: number,
    is_email_audit_result: number,
    inquiry_type: string,
    website_urls: string,
    inquiry_files: htmlFileInputProptype[],

): Promise<any> => {
    const url = `${baseURL}/contact-us`;
    const data = new FormData();

    data.append("name", name);
    data.append("email", email);
    data.append("message", message);
    data.append("is_audit", is_audit.toString());
    data.append("is_email_audit_result", is_email_audit_result.toString());
    data.append("inquiry_type", inquiry_type);
    data.append("website_urls", website_urls);
    //console.log("inquiry_files ==>", inquiry_files);
    inquiry_files.map((item, index) => {
        const files = item.file;
        data.append(`inquiry_files[${index}]`, files[0]);
    })

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

const getCurrentWindowAPI = async (): Promise<any> => {
    const url = `https://geolocation-db.com/json/`;
    const data = new FormData();

    try {
        const response = await fetch(url);
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

const ScanReportProblemAPI = async (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    website: string,
    problem: string,
    message: string,
    selectedFile: File,
): Promise<any> => {
    const url = `${baseURL}/compliance-report-problem`;
    const data = new FormData();

    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("email", email);
    data.append("phone", phone);
    data.append("url", website);
    data.append("problem_type", problem);
    data.append("description", message);
    data.append("screenshot_file", selectedFile);



    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { ScanningProcessAPI, ScannReportAPI, quoteFormAPI, getCurrentWindowAPI, ScanReportProblemAPI, GetReportPDFAPI }