//import logo from "../../Assets/img/accessibility-checker-logo.svg";
import { useTranslation } from "react-i18next";
import useWindowSize from '../../Helpers/useWindowSize';
import { useEffect, useState } from "react";

const CustomLoader = () => {

    const { t } = useTranslation();

    const { windowHeight } = useWindowSize();

    const [scale, setScale] = useState(1);

    useEffect(() => {
        if (windowHeight) {
            const temp = (windowHeight * .44) / 480;
            setScale(temp);
        }
    }, [windowHeight])

    return (
        <>
            <div className="cat-container">
                <div className="all-wrap">
                    <div className="scale-wrapper" style={{ transform: `scale(${scale})` }}>
                        <div className="all">
                            <div className="yarn"></div>
                            <div className="cat-wrap">
                                <div className="cat">
                                    <div className="cat-upper">
                                        <div className="cat-leg"></div>
                                        <div className="cat-leg"></div>
                                        <div className="cat-head">
                                            <div className="cat-ears">
                                                <div className="cat-ear"></div>
                                                <div className="cat-ear"></div>
                                            </div>
                                            <div className="cat-face">
                                                <div className="cat-eyes"></div>
                                                <div className="cat-mouth"></div>
                                                <div className="cat-whiskers"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cat-lower-wrap">
                                        <div className="cat-lower">
                                            <div className="cat-leg">
                                                <div className="cat-leg">
                                                    <div className="cat-leg">
                                                        <div className="cat-leg">
                                                            <div className="cat-leg">
                                                                <div className="cat-leg">
                                                                    <div className="cat-leg">
                                                                        <div className="cat-leg">
                                                                            <div className="cat-leg">
                                                                                <div className="cat-leg">
                                                                                    <div className="cat-leg">
                                                                                        <div className="cat-leg">
                                                                                            <div className="cat-leg">
                                                                                                <div className="cat-leg">
                                                                                                    <div className="cat-leg">
                                                                                                        <div className="cat-leg">
                                                                                                            <div className="cat-paw"></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cat-leg">
                                                <div className="cat-leg">
                                                    <div className="cat-leg">
                                                        <div className="cat-leg">
                                                            <div className="cat-leg">
                                                                <div className="cat-leg">
                                                                    <div className="cat-leg">
                                                                        <div className="cat-leg">
                                                                            <div className="cat-leg">
                                                                                <div className="cat-leg">
                                                                                    <div className="cat-leg">
                                                                                        <div className="cat-leg">
                                                                                            <div className="cat-leg">
                                                                                                <div className="cat-leg">
                                                                                                    <div className="cat-leg">
                                                                                                        <div className="cat-leg">
                                                                                                            <div className="cat-paw"></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cat-tail">
                                                <div className="cat-tail">
                                                    <div className="cat-tail">
                                                        <div className="cat-tail">
                                                            <div className="cat-tail">
                                                                <div className="cat-tail">
                                                                    <div className="cat-tail">
                                                                        <div className="cat-tail">
                                                                            <div className="cat-tail">
                                                                                <div className="cat-tail">
                                                                                    <div className="cat-tail">
                                                                                        <div className="cat-tail">
                                                                                            <div className="cat-tail">
                                                                                                <div className="cat-tail">
                                                                                                    <div className="cat-tail">
                                                                                                        <div className="cat-tail -end"></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="content content--husky">
                <div className="mountain"></div>
                <div className="mountain"></div>
                <div className="husky">
                    <div className="husky-mane">
                        <div className="husky-coat"></div>
                    </div>
                    <div className="husky-body">
                        <div className="husky-head">
                            <div className="husky-ear"></div>
                            <div className="husky-ear"></div>
                            <div className="husky-face">
                                <div className="husky-eye"></div>
                                <div className="husky-eye"></div>
                                <div className="husky-nose"></div>
                                <div className="husky-mouth">
                                    <div className="husky-lips"></div>
                                    <div className="husky-tongue"></div>
                                </div>
                            </div>
                        </div>
                        <div className="husky-torso"></div>
                    </div>
                    <div className="husky-legs">
                        <div className="husky-front-legs">
                            <div className="husky-leg"></div>
                            <div className="husky-leg"></div>
                        </div>
                        <div className="husky-hind-leg">
                        </div>
                    </div>
                    <div className="husky-tail">
                        <div className="husky-tail">
                            <div className="husky-tail">
                                <div className="husky-tail">
                                    <div className="husky-tail">
                                        <div className="husky-tail">
                                            <div className="husky-tail"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ display: 'none' }}>
                    <defs>
                        <filter id="squiggly-0">
                            <feTurbulence id="turbulence" baseFrequency="0.02" numOctaves="3" result="noise" seed="0" />
                            <feDisplacementMap id="displacement" in="SourceGraphic" in2="noise" scale="2" />
                        </filter>
                        <filter id="squiggly-1">
                            <feTurbulence id="turbulence" baseFrequency="0.02" numOctaves="3" result="noise" seed="1" />
                            <feDisplacementMap in="SourceGraphic" in2="noise" scale="3" />
                        </filter>
                        <filter id="squiggly-2">
                            <feTurbulence id="turbulence" baseFrequency="0.02" numOctaves="3" result="noise" seed="2" />
                            <feDisplacementMap in="SourceGraphic" in2="noise" scale="2" />
                        </filter>
                        <filter id="squiggly-3">
                            <feTurbulence id="turbulence" baseFrequency="0.02" numOctaves="3" result="noise" seed="3" />
                            <feDisplacementMap in="SourceGraphic" in2="noise" scale="3" />
                        </filter>
                        <filter id="squiggly-4">
                            <feTurbulence id="turbulence" baseFrequency="0.02" numOctaves="3" result="noise" seed="4" />
                            <feDisplacementMap in="SourceGraphic" in2="noise" scale="1" />
                        </filter>
                    </defs>
                </svg>
            </div> */}
            <div className='fullscreen-loader-wrapper'>
                <div className='fullscreen-loader'>
                    {/* <div className='logo-wrapper'>
                        <img src={logo} alt={t('logo-alt-text')} />
                    </div> */}
                    <div className='loader-wrapper'>
                        <div className='loader-text'> <strong> {t("loader-text")} </strong> </div>
                        <div className="linear-progress">
                            <div className="bar bar1"></div>
                            <div className="bar bar2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomLoader