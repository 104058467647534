import i18n from "i18next";
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

//i18N Initialization

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.REACT_APP_BASE_URL}/public/translations/{{lng}}.json`,
      crossDomain: true,
    },
    lng: "en", //default language
    debug: false,
    fallbackLng: "en",
    keySeparator: false,
    nsSeparator: "|",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    }
  });

export default i18n;