import { useTranslation } from "react-i18next";

const AllInOneAccessibilityCTA = () => {
    const { t } = useTranslation();
    return (
        <div className="cts-box">
            <div className="cts-box-text">
                <h2 className="h3 mb-3">{t("Kick-start Website Accessibility Journey with All in One Accessibility®")}!</h2>
                <ul>
                    <li>{t("An accessibility solution for quick website accessibility improvement")}</li>
                    <li>{t("Efficient - 2-minute installation with any website platform")}</li>
                    <li>{t("A lightweight accessibility widget")}</li>
                </ul>
            </div>
            <div className="cts-box-btn">
                <a href="https://www.skynettechnologies.com/add-ons/product/all-in-one-accessibility-pro/?attribute_package-name=Small+Site+%2850K+Page+Views%2Fmo%29&attribute_subscription=Monthly" className="btn btn-light" target='_blank'>
                    <span>{t("Get Started")}</span>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 9.5 16">
                        <path d="M1.5,0L0,1.5L6.5,8L0,14.5L1.5,16l8-8L1.5,0z"></path>
                    </svg>
                </a>
            </div>
        </div>
    )
}

export default AllInOneAccessibilityCTA