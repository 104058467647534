import { LOADING, SET_LEVEL_FAIL_COUNT, SET_LEVEL_FAIL_COUNT_FIXED_BY_WIDGET, SET_PAID, WEBSITE_RESCANNING_PROCESS, WEBSITE_SCANNING_PROCESS, WEBSITE_SCANNING_PROCESS_PER, WEBSITE_SCREENSHOT, WEBSITE_URL, WEBSITE_WIDGET } from "../../Actions/Types";
import { getCurrentWindowAPI, GetReportPDFAPI, quoteFormAPI, ScanningProcessAPI, ScannReportAPI } from "../../Api/ScanningProcessAPI";

const ScanningProcessService = (
    url: string,
    is_first: number,
    lang: string,
    dispatch: Function,
    setViolationsGroupList: Function,
    setErrorViolationsTotal: Function,
    setErrorViolationsTotalFixedByWidget: Function,
    setSuccessViolationsTotal: Function,
    setAccessibilitySystemDetected: Function,
    setRequestDataObj: Function,
    setScreenError: Function,
    setApiRecall: Function,
) => {



    if (is_first === 2) {

        console.log("Start Time", Date());
        setRequestDataObj(null);

        dispatch({
            type: WEBSITE_SCREENSHOT,
            payload: null,
        });
        dispatch({
            type: WEBSITE_SCANNING_PROCESS,
            payload: true,
        });
        setAccessibilitySystemDetected('');
    }


    dispatch({
        type: LOADING,
        payload: true,
    });
    ScanningProcessAPI(url, is_first, lang).then((response) => {

        //console.log("response.status ==>", response.status, response);

        if (response && response.status !== 1) {

            /* dispatch({
                type: WEBSITE_URL,
                payload: url,
            }); */

            if (is_first === 2) {
                ScanningProcessService(url, 1, lang, dispatch, setViolationsGroupList, setErrorViolationsTotal, setErrorViolationsTotalFixedByWidget, setSuccessViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, setApiRecall);
                setTimeout(() => {
                    ScanningProcessService(url, 0, lang, dispatch, setViolationsGroupList, setErrorViolationsTotal, setErrorViolationsTotalFixedByWidget, setSuccessViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, setApiRecall);
                }, 500)

            }
            else if (is_first === 0) {
                /* dispatch({
                    type: WEBSITE_SCANNING_PROCESS,
                    payload: true,
                });
                dispatch({
                    type: WEBSITE_RESCANNING_PROCESS,
                    payload: false,
                }); */
                dispatch({
                    type: WEBSITE_SCREENSHOT,
                    payload: response.page_image,
                });
                /* dispatch({
                    type: WEBSITE_WIDGET,
                    payload: false,
                });
                dispatch({
                    type: SET_PAID,
                    payload: false,
                });
                dispatch({
                    type: SET_LEVEL_FAIL_COUNT,
                    payload: null,
                }); */

                /* setViolationsGroupList([]);
                setErrorViolationsTotal(0);
                setErrorViolationsTotalFixedByWidget(0);
                setSuccessViolationsTotal(0);

                setAccessibilitySystemDetected(''); */
            }
            else {
                console.log("is_first", is_first);
                console.log("response.completed_percentage", response.completed_percentage);
                if (response.completed_percentage === 100) {
                    console.log("response.reload", response.reload);
                    if (response.reload === true) {
                        console.log("response.reload === true");
                        setApiRecall(true);
                    }
                    else {
                        console.log("response.completed_percentage ===100")
                        console.log("End Time ==>", Date());
                        //setApiRecall(false);
                        dispatch({
                            type: WEBSITE_SCANNING_PROCESS_PER,
                            payload: response.completed_percentage,
                        });
                        dispatch({
                            type: WEBSITE_SCANNING_PROCESS_PER,
                            payload: 100,
                        });

                        dispatch({
                            type: WEBSITE_WIDGET,
                            payload: response.widget_purchased ? true : false,
                        });

                        dispatch({
                            type: SET_PAID,
                            payload: response.is_paid_free === 2 ? true : false,
                        });

                        dispatch({
                            type: SET_LEVEL_FAIL_COUNT,
                            payload: response.level_fail_counts,
                        });

                        dispatch({
                            type: SET_LEVEL_FAIL_COUNT_FIXED_BY_WIDGET,
                            payload: response.level_fail_counts_fixed_by_widget,
                        });

                        setTimeout(() => {
                            dispatch({
                                type: WEBSITE_SCANNING_PROCESS,
                                payload: false,
                            });

                            dispatch({
                                type: WEBSITE_RESCANNING_PROCESS,
                                payload: true,
                            });

                            dispatch({
                                type: LOADING,
                                payload: false,
                            });
                        }, 1000);

                        setViolationsGroupList(response.group_data);
                        setErrorViolationsTotal(response.total_fail);
                        setErrorViolationsTotalFixedByWidget(response.total_fail_fixed_by_widget);
                        setSuccessViolationsTotal(response.total_success);


                        setAccessibilitySystemDetected(response.accessibility_system_detected);
                        setRequestDataObj(response.request_data);
                    }

                }

                if (response.completed_percentage !== 100) {
                    console.log("response.completed_percentage !==100");
                    dispatch({
                        type: WEBSITE_SCANNING_PROCESS_PER,
                        payload: response.completed_percentage,
                    });
                    setApiRecall(true);
                }
            }


        } else {
            setScreenError(true);
            setRequestDataObj(response.request_data);
            dispatch({
                type: WEBSITE_SCANNING_PROCESS,
                payload: false,
            });
            dispatch({
                type: WEBSITE_RESCANNING_PROCESS,
                payload: false,
            });
            dispatch({
                type: WEBSITE_URL,
                payload: null,
            });
            dispatch({
                type: WEBSITE_SCREENSHOT,
                payload: null,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        }

    });
};

const ScanReportService = (
    id: number,
    accessibilityScore: number,
    scannerUserFullName: string,
    scannerUserEmail: string,
    customizeReport: number,
    reportLogoURL: string,
    brandColor: string,
    setLoading: Function,
    setSuccessResponse: Function,
    setCustomizeReport: Function,
    setReportLogoURL: Function,
    setBrandColor: Function
) => {
    setLoading(true);
    ScannReportAPI(id, accessibilityScore, scannerUserFullName, scannerUserEmail, customizeReport, reportLogoURL, brandColor).then((response) => {
        if (response.Data) {
            setSuccessResponse(true);
            setLoading(false);
            setCustomizeReport(0);
            setReportLogoURL('');
            setBrandColor('');
            //window.open(response.data, '_blank');
        }
    })
};


interface htmlFileInputProptype {
    id: string,
    file: any
}

const quoteFormService = (
    name: string,
    email: string,
    message: string,
    is_audit: number,
    is_email_audit_result: number,
    inquiry_type: string,
    website_urls: string,
    inquiry_files: htmlFileInputProptype[],
    setQuoteLoading: Function,
    setQuoteFormSuccess: Function
) => {
    setQuoteLoading(true);
    quoteFormAPI(name, email, message, is_audit, is_email_audit_result, inquiry_type, website_urls, inquiry_files).then((response) => {
        if (response.Data) {

            setQuoteLoading(false);
            setQuoteFormSuccess(true);
        }
    })
};


const getCurrentWindowService = (setMyIp: Function) => {
    getCurrentWindowAPI().then((response) => {
        if (response) {
            setMyIp(response.IPv4);
        }
        //console.info("2 response ==>", response)
    })
}

const getReportPDFService = (id: number, setPDFURL: Function, scoreImage: string, wcagImage: string, langcode: string) => {
    GetReportPDFAPI(id, scoreImage, wcagImage, langcode).then(response => {
        if (response) {
            setPDFURL(response.report_url);
        }
    })
}

export { ScanningProcessService, ScanReportService, quoteFormService, getCurrentWindowService, getReportPDFService }