import { debounce } from 'lodash';
import { useState, useEffect, useCallback } from 'react';

const useWindowSize = () => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

    const handleResize = useCallback(debounce(() => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    }, 500), []);

    useEffect(() => {
        window.addEventListener('resize', handleResize, { passive: true });
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return { windowWidth, windowHeight }

}

export default useWindowSize;