import WebsiteScreen from "../WebsiteScreen";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useSelector } from "react-redux";
import { Col, Container, Modal, Placeholder, Row, Form, Button } from "react-bootstrap";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import axios from "axios";

import alertIcon from "../../Assets/img/alert-icon.svg";
import aioaBanner from "../../Assets/img/AIOA-banner.webp";
import manualBanner from "../../Assets/img/Manual-Accessibility-Audit-Report.webp";



interface PropType {
    accessibilitySystemDetected: string,
    accessibilityScore: number,
    errorViolationsTotal: number,
    errorViolationsTotalFixedByWidget: number,
    fixedByWidget: boolean,
    setFixedByWidget: Function,
}

interface ForwardedRefs {
    scoreRef: React.RefObject<HTMLDivElement>;
    wcagRef: React.RefObject<HTMLDivElement>;
}

const AIOAPromoBanner = () => {
    return (
        <div className="aioa-promo-banner">
            <a href="https://www.skynettechnologies.com/add-ons/product/all-in-one-accessibility-pro/?attribute_package-name=Small+Site+%2850K+Page+Views%2Fmo%29&attribute_subscription=Monthly" target="_blank">
                <img src={aioaBanner} alt="Get All in One Accessibility" className="img-fluid" />
            </a>
        </div>
    )
}

const ManualAuditBanner = () => {
    return (
        <div className="aioa-promo-banner">
            <a href="https://www.skynettechnologies.com/website-accessibility-audit" target="_blank">
                <img src={manualBanner} alt="Manual Accessibility Audit" className="img-fluid" />
            </a>
        </div>
    )
}

const WebsiteURLSection = forwardRef<ForwardedRefs, PropType>(
    ({ accessibilitySystemDetected, accessibilityScore, errorViolationsTotal, errorViolationsTotalFixedByWidget, fixedByWidget, setFixedByWidget }, ref) => {

        const { t, i18n } = useTranslation();

        //const { accessibilitySystemDetected, accessibilityScore, errorViolationsTotal, errorViolationsTotalFixedByWidget, fixedByWidget, setFixedByWidget } = prop;

        const { isWidget, isPaid, standard, levelFailCounts, levelFailCountsFixedByWidget } = useSelector((store: StoreProptypes) => store);

        const [limitations, setLimitations] = useState('');

        const [scoreDescription, setScoreDescription] = useState('');


        const [showLimitationsModal, setShowLimitationsModal] = useState(false);
        const [showScoreDescriptionModal, setShowScoreDescriptionModal] = useState(false);

        const getLimitationsApi = axios.get(`${process.env.REACT_APP_BASE_URL}/public/translations/limitations/${i18n.language}.html`);

        const getScoreDescriptionApi = axios.get(`${process.env.REACT_APP_BASE_URL}/public/translations/accessibility-score/${i18n.language}.html`);

        const [levelA, setLevelA] = useState<number>(0);
        const [levelAA, setLevelAA] = useState<number>(0);
        const [levelAAA, setLevelAAA] = useState<number>(0);




        useEffect(() => {
            if (showLimitationsModal) {
                setLimitations("");
                getLimitationsApi.then(response => {
                    if (response.data) {
                        setLimitations(response.data);
                    }
                })

            }
        }, [showLimitationsModal])

        useEffect(() => {
            if (showScoreDescriptionModal) {
                setScoreDescription("");
                getScoreDescriptionApi.then(response => {
                    if (response.data) {
                        setScoreDescription(response.data);
                    }
                })

            }
        }, [showScoreDescriptionModal])


        useEffect(() => {
            if (levelFailCountsFixedByWidget && levelFailCounts) {
                let tempA, tempAA, tempAAA: number;
                if (fixedByWidget) {
                    tempA = levelFailCounts.A - levelFailCountsFixedByWidget.A;
                    tempAA = levelFailCounts.AA - levelFailCountsFixedByWidget.AA;
                    tempAAA = levelFailCounts.AAA - levelFailCountsFixedByWidget.AAA;
                }
                else {
                    tempA = levelFailCounts.A;
                    tempAA = levelFailCounts.AA;
                    tempAAA = levelFailCounts.AAA;
                }

                const total = tempA + tempAA + tempAAA;

                setLevelA(tempA / total * 100);
                setLevelAA(tempAA / total * 100);
                setLevelAAA(tempAAA / total * 100);

            }

        }, [levelFailCounts, levelFailCountsFixedByWidget, fixedByWidget])



        const scoreLocalRef = useRef<HTMLDivElement>(null);
        const wcagLocalRef = useRef<HTMLDivElement>(null);

        useImperativeHandle(ref, () => ({
            scoreRef: scoreLocalRef,
            wcagRef: wcagLocalRef,
        }));

        return (

            <div className="website-screen-details-wrap">

                <Container>

                    {(accessibilitySystemDetected !== '' && accessibilitySystemDetected !== null && accessibilitySystemDetected !== "All in One Accessibility™") ?
                        <div className="system-detected">
                            {t("Overlay Detected")}
                        </div>
                        : (
                            <>
                                {isWidget ? (
                                    <>
                                        {isPaid ? <>
                                            <div className="system-detected">
                                                <span> {t("All in One Accessibility Detected")} </span>
                                            </div>
                                        </> : <>
                                            <div className={`system-detected ${fixedByWidget ? 'mb-2' : ''}`}>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label={t("All in One Accessibility Free Version Detected")}
                                                    className="form-check-reverse text-start w-100"
                                                    checked={fixedByWidget}
                                                    onChange={() => setFixedByWidget(!fixedByWidget)}
                                                />
                                            </div>
                                        </>}

                                    </>
                                ) : (
                                    <>
                                        <div className={`system-detected ${fixedByWidget ? 'mb-2' : ''}`}>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label={t("No Overlay Detected")}
                                                className="form-check-reverse text-start w-100"
                                                checked={fixedByWidget}
                                                onChange={() => setFixedByWidget(!fixedByWidget)}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                    {fixedByWidget ? <div className="accessibility-score-notice text-danger fw-medium"> {t("Score Disclaimer")}</div> : <></>}


                    <div className="website-screen-details">

                        <Row xs={1} lg={3}>
                            <Col className="mb-4 mb-lg-0"> <div className="h-100"> <WebsiteScreen /> </div></Col>
                            <Col className="mb-4 mb-lg-0">
                                <div className="h-100">
                                    <div className="aioa_dashboard-violations-graph-box" ref={scoreLocalRef}>
                                        <h2 className="aioa_dashboard-violations-graph-box-title h5">{t('Accessibility Score')}
                                            <Button variant="link" aria-label={t("Accessibility Score Help")} className="p-0 ms-1" onClick={() => setShowScoreDescriptionModal(true)}>
                                                <img src={alertIcon} alt="" style={{ width: 25 }} />
                                            </Button>
                                        </h2>
                                        <div className="aioa_dashboard-violations-semi-donut-graph" style={{ "--percentage": accessibilityScore, "--fill": '#4A6681' } as React.CSSProperties}>
                                            <div className="aioa_dashboard-violations-graph-box-per h1 fw-bold">{accessibilityScore.toFixed(2)}%</div>
                                            <div className={`aioa_dashboard-violations-graph-box-info h6 mb-0 ${accessibilityScore > 85 ? `text-success success` : accessibilityScore > 49 ? `text-warning warning` : `text-danger danger`}`}>
                                                {accessibilityScore > 85 ? t("Compliant") : accessibilityScore > 49 ? t("Semi Compliant") : t("Not Compliant")}
                                            </div>
                                        </div>
                                        <div className="mt-0 mt-lg-3 score-limitation-wrapper"> <Trans i18nKey='Accessibility Score Limitation Note'> Automated Accessibility score has limitations, <br></br> <a role="button" className="text-underline text-info" onClick={() => setShowLimitationsModal(true)}> click here to learn more </a> </Trans></div>
                                    </div>
                                </div>
                            </Col>
                            <Col>

                                <div className="h-100">
                                    <div className="aioa_dashboard-violations-graph-box levels-graph" ref={wcagLocalRef}>
                                        <h2 className="aioa_dashboard-violations-graph-box-title h5">{t('WCAG 2.0, 2.1 & 2.2')}</h2>
                                        <div className="aioa_dashboard-violations-semi-donut-graph" style={{ "--percentage": accessibilityScore, "--fill": '#4A6681' } as React.CSSProperties}>
                                            <div className="graph" data-name="A" style={{ "--percentage": '100', "--d-percentage": Math.round(levelA), "--fill": '#CADAEA' } as React.CSSProperties}></div>
                                            <div className="graph" data-name="AA" style={{ "--percentage": Math.round(levelAAA + levelAA), "--d-percentage": Math.round(levelAA), "--fill": '#92B6D8' } as React.CSSProperties}></div>
                                            <div className="graph" data-name="AAA" style={{ "--percentage": Math.round(levelAAA), "--d-percentage": Math.round(levelAAA), "--fill": '#4A6681' } as React.CSSProperties}></div>
                                            <div className="aioa_dashboard-violations-graph-box-per h1 fw-bold">
                                                {fixedByWidget ? (errorViolationsTotal - errorViolationsTotalFixedByWidget) : errorViolationsTotal}
                                            </div>
                                            <div className={`aioa_dashboard-violations-graph-box-info h6 mb-0 text-danger`}>
                                                {t("Failed Checks")}
                                            </div>
                                        </div>
                                        <div className="mt-0 mt-lg-2">
                                            <div className="levels-legend">
                                                <div className="level" data-percentage={Math.round(levelA)}>A</div>
                                                <div className="level" data-percentage={Math.round(levelAA)}>AA</div>
                                                <div className="level" data-percentage={Math.round(levelAAA)}>AAA</div>
                                            </div>
                                        </div>
                                        <div className="mt-2 wcag-compliance-summary">
                                            {accessibilityScore > 85 ? <Trans
                                                i18nKey="Compliant under"
                                                defaults="Compliant under <bold>{{- standard}}</bold>"
                                                values={{
                                                    standard: standard,
                                                }}
                                                components={{
                                                    bold: <strong />,
                                                }}
                                            /> : accessibilityScore > 49 ? <Trans
                                                i18nKey="Semi compliant under"
                                                defaults="Semi compliant under <bold>{{- standard}}</bold>"
                                                values={{
                                                    standard: standard,
                                                }}
                                                components={{
                                                    bold: <strong />,
                                                }}
                                            /> : <Trans
                                                i18nKey="Not compliant under"
                                                defaults="Not compliant under <bold>{{- standard}}</bold>"
                                                values={{
                                                    standard: standard,
                                                }}
                                                components={{
                                                    bold: <strong />,
                                                }}
                                            />}

                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>


                    </div>

                    {(accessibilitySystemDetected !== '' && accessibilitySystemDetected !== null && accessibilitySystemDetected !== "All in One Accessibility™") ?
                        <ManualAuditBanner />
                        : (
                            <>
                                {isWidget ? (
                                    <>
                                        {isPaid ? <>
                                        </> : <>
                                            <AIOAPromoBanner />
                                        </>}

                                    </>
                                ) : (
                                    <>
                                        <AIOAPromoBanner />
                                    </>
                                )}
                            </>
                        )}

                    
                </Container>
                <Modal show={showLimitationsModal} onHide={() => setShowLimitationsModal(false)} animation={false} size="lg" centered>
                    <Modal.Body>
                        <button className="modal-close-button" aria-label={t("Close Modal Popup")} onClick={() => setShowLimitationsModal(false)}></button>
                        <div className="px-5 py-3">
                            <h2 className="text-center h4 text-primary fw-bold mb-4">{t("Automated Accessibility Score Limitations")}</h2>
                            {limitations ? <div dangerouslySetInnerHTML={{ __html: limitations }}></div> :
                                <Placeholder animation="glow" className="">
                                    <Placeholder />
                                    <Placeholder style={{ width: "80%" }} />
                                    <Placeholder style={{ width: "50%" }} />
                                    <Placeholder style={{ width: "30%" }} />
                                    <Placeholder />
                                    <Placeholder style={{ width: "80%" }} />
                                    <Placeholder />
                                    <Placeholder style={{ width: "30%" }} />
                                    <Placeholder style={{ width: "50%" }} />
                                    <Placeholder />
                                    <Placeholder style={{ width: "50%" }} />
                                    <Placeholder style={{ width: "40%" }} />
                                    <Placeholder style={{ width: "75%" }} />
                                    <Placeholder />
                                    <Placeholder style={{ width: "30%" }} />
                                    <Placeholder style={{ width: "80%" }} />
                                    <Placeholder />
                                    <Placeholder style={{ width: "50%" }} />
                                    <Placeholder />
                                    <Placeholder style={{ width: "20%" }} />
                                    <Placeholder style={{ width: "70%" }} />
                                </Placeholder>
                            }


                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={showScoreDescriptionModal} onHide={() => setShowScoreDescriptionModal(false)} animation={false} size="lg" centered>
                    <Modal.Body>
                        <button className="modal-close-button" aria-label={t("Close Modal Popup")} onClick={() => setShowScoreDescriptionModal(false)}></button>
                        <div className="px-5 py-3">
                            <h2 className="text-center h4 text-primary fw-bold mb-4">{t("How Accessibility Score is Calculated?")}</h2>
                            {scoreDescription ? <div dangerouslySetInnerHTML={{ __html: scoreDescription }}></div> :
                                <Placeholder animation="glow" className="">
                                    <Placeholder />
                                    <Placeholder style={{ width: "80%" }} />
                                    <Placeholder style={{ width: "50%" }} />
                                    <Placeholder style={{ width: "30%" }} />
                                    <Placeholder />
                                    <Placeholder style={{ width: "80%" }} />
                                    <Placeholder />
                                    <Placeholder style={{ width: "30%" }} />
                                    <Placeholder style={{ width: "50%" }} />
                                    <Placeholder />
                                    <Placeholder style={{ width: "50%" }} />
                                    <Placeholder style={{ width: "40%" }} />
                                    <Placeholder style={{ width: "75%" }} />
                                    <Placeholder />
                                    <Placeholder style={{ width: "30%" }} />
                                    <Placeholder style={{ width: "80%" }} />
                                    <Placeholder />
                                    <Placeholder style={{ width: "50%" }} />
                                    <Placeholder />
                                    <Placeholder style={{ width: "20%" }} />
                                    <Placeholder style={{ width: "70%" }} />
                                </Placeholder>
                            }


                        </div>
                    </Modal.Body>
                </Modal>

            </div>



        )
    }
);

export default WebsiteURLSection;