import { violationDetails } from "../../PropTypes/ScanningScreenPropType";
import { useTranslation } from "react-i18next";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface propType {
    element: violationDetails[],
    violationType: string,
    startIndex: number
}

const ElementsListMain = (prop: propType) => {

    const { t } = useTranslation();

    const { element, violationType, startIndex } = prop;



    return (
        <>
            {element && element.length > 0 && (
                <>
                    {element.map((item, index) => {
                        const text = item.context.replace(/\s+/g, ' ').trim();


                        return (
                            <tr key={item.id}>
                                <td> <strong> {startIndex + index} </strong></td>
                                <td>
                                    <div className="code-container">
                                        <SyntaxHighlighter language="javascript" style={a11yDark} wrapLongLines={true}>
                                            {`${text}`}
                                        </SyntaxHighlighter>
                                    </div>


                                </td>
                                <td>
                                    {violationType === 'success' ?
                                        <div className="violation-status total-success"> {t("Success")}</div>
                                        :
                                        <div className="violation-status total-error"> {t("Failed")}</div>

                                    }
                                </td>
                            </tr>
                        )
                    })}
                </>
            )}

        </>
    )
}

export default ElementsListMain;