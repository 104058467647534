
import { Container, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MetaDataProivder from '../../Components/MetaDataProvider';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CustomHeader from '../../Components/CustomHeader';
import Footer from '../../Components/Footer';




const ScoreLimitationsPage = () => {

  const { t, i18n } = useTranslation();

  const getTranslationApi = axios.get(`${process.env.REACT_APP_BASE_URL}/public/translations/limitations/${i18n.language}.html`);

  const [privacyHTML, setPrivacyHTML] = useState("");

  useEffect(() => {
    setPrivacyHTML('');

    getTranslationApi.then(response => {
      if (response.data) {
        setPrivacyHTML(response.data);
      }
    })

  }, [])

  return (
    <>
      <MetaDataProivder />
      <div className='scan-details-page-main'>
        <CustomHeader />
        <div className="py-5">
          <div className="inner-wrapper">
            <Container>
              <h1>{t("Automated Accessibility Score Limitations")}</h1>
              {privacyHTML ? <div dangerouslySetInnerHTML={{ __html: privacyHTML }}></div> :
                <Placeholder animation="glow" className="">
                  <Placeholder />
                  <Placeholder style={{ width: "80%" }} />
                  <Placeholder style={{ width: "50%" }} />
                  <Placeholder style={{ width: "30%" }} />
                  <Placeholder />
                  <Placeholder style={{ width: "80%" }} />
                  <Placeholder />
                  <Placeholder style={{ width: "30%" }} />
                  <Placeholder style={{ width: "50%" }} />
                  <Placeholder />
                  <Placeholder style={{ width: "50%" }} />
                  <Placeholder style={{ width: "40%" }} />
                  <Placeholder style={{ width: "75%" }} />
                  <Placeholder />
                  <Placeholder style={{ width: "30%" }} />
                  <Placeholder style={{ width: "80%" }} />
                  <Placeholder />
                  <Placeholder style={{ width: "50%" }} />
                  <Placeholder />
                  <Placeholder style={{ width: "20%" }} />
                  <Placeholder style={{ width: "70%" }} />
                </Placeholder>
              }

            </Container>


          </div>
        </div>
        <Footer />
      </div>

    </>
  )

}

export default ScoreLimitationsPage;