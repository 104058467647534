const LOADING = "LOADING";
const SET_MESSAGE = "SET_MESSAGE";
const CLEAR_MESSAGE = "CLEAR_MESSAGE";
const WEBSITE_URL = "WEBSITE_URL";
const WEBSITE_SCANNING_PROCESS = "WEBSITE_SCANNING_PROCESS";
const WEBSITE_SCANNING_PROCESS_PER = "WEBSITE_SCANNING_PROCESS_PER";
const WEBSITE_RESCANNING_PROCESS = "WEBSITE_RESCANNING_PROCESS";
const WEBSITE_SCREENSHOT = "WEBSITE_SCREENSHOT";
const WEBSITE_WIDGET = "WEBSITE_WIDGET";
const SET_PAID = "SET_PAID";
const SET_STANDARD = "SET_STANDARD";
const SET_LEVEL_FAIL_COUNT = "SET_LEVEL_FAIL_COUNT";
const SET_LEVEL_FAIL_COUNT_FIXED_BY_WIDGET = "SET_LEVEL_FAIL_COUNT_FIXED_BY_WIDGET";
const SET_FORM_SUBMIT_FLAG = "SET_FORM_SUBMIT_FLAG";
const SET_LANGUAGE = "SET_LANGUAGE";

export {
    LOADING,
    SET_MESSAGE,
    CLEAR_MESSAGE,
    WEBSITE_URL,
    WEBSITE_SCANNING_PROCESS,
    WEBSITE_SCANNING_PROCESS_PER,
    WEBSITE_RESCANNING_PROCESS,
    WEBSITE_SCREENSHOT,
    WEBSITE_WIDGET,
    SET_STANDARD,
    SET_PAID,
    SET_LEVEL_FAIL_COUNT,
    SET_LEVEL_FAIL_COUNT_FIXED_BY_WIDGET,
    SET_FORM_SUBMIT_FLAG,
    SET_LANGUAGE
};