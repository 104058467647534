
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MetaDataProivder from '../../Components/MetaDataProvider';
import { useState } from 'react';
import CustomHeader from '../../Components/CustomHeader';
import Footer from '../../Components/Footer';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ScanReportProblemAPI } from '../../Api/ScanningProcessAPI';


const ReportProblemPage = () => {

  const { t } = useTranslation();



  const [website, setWebsite] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [problem, setProblem] = useState('');
  const [message, setMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState<File>();


  const [validationMessage, setValidationMessage] = useState("");

  const [formSubmitMessage, setFormSubmitMessage] = useState("");


  const HandleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitMessage('');
    let httpRegex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;

    //console.log("userWebsiteURL ==>", httpRegex.test(website))

    if (website === '') {
      setValidationMessage(t("Please enter website URL."));
    } else if (!(httpRegex.test(website))) {
      setValidationMessage(t("Please enter valid website URL."));
    } else if (firstName === '') {
      setValidationMessage(t("Please enter first name"));
    }
    else if (lastName === '') {
      setValidationMessage(t("Please enter last name"));
    }
    else if (email === '') {
      setValidationMessage(t('Please enter your email address'));
    }
    else if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email) === false) {
      setValidationMessage(t('Please enter valid email address'));
    }
    else if (phone === '') {
      setValidationMessage(t("Please enter your phone number"));
    }
    else if (problem === '') {
      setValidationMessage(t("Please select a problem"));
    }
    else if (message === '') {
      setValidationMessage(t("Please describe your problem"));
    }
    else if (!selectedFile) {
      setValidationMessage(t("Please upload a screenshot or video recording"));
    }
    else {
      setValidationMessage("");
      ScanReportProblemAPI(firstName, lastName, email, phone, website, problem, message, selectedFile).then((response) => {
        if (response.status === 200) {
          alert("submitted");
          setFormSubmitMessage(t("Thank your for reporting the problem"));
        }
        else {
          alert("not Submitted");
        }
      })
    }
  }

  const handleFileInput = (e: any) => {
    const target = e.target;
    const fileUploaded = target.files;

    if (fileUploaded) {
      setSelectedFile(fileUploaded[0]);
      /* let reader = new FileReader();
      reader.readAsDataURL(fileUploaded[0]);

      reader.onload = (e) => {
        console.log("reader.result ==>", fileUploaded[0]);
      } */
    }
  }

  return <>

    <MetaDataProivder />
    <div className='scan-details-page-main report-a-problem-page'>
      <CustomHeader />
      <div className="py-5">
        <div className="inner-wrapper">
          <Container>
            <div className='report-a-problem-form-wraper'>
              <h1>{t("Report a Problem")}</h1>
              {validationMessage && (
                <>
                  <Alert variant='danger'>
                    {validationMessage}
                  </Alert>
                </>
              )}
              {formSubmitMessage && (
                <>
                  <Alert variant='success'>
                    {formSubmitMessage}
                  </Alert>
                </>
              )}
              <Form onSubmit={(e) => HandleSubmit(e)}>
                <Row xs={1} md={2}>
                  <Col>
                    <Form.Group className="mb-4" controlId="first_name">
                      <Form.Control
                        type="text"
                        placeholder={t("First Name")}
                        aria-label={t("First Name")}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-4" controlId="last_name">
                      <Form.Control
                        type="text"
                        placeholder={t("Last Name")}
                        aria-label={t("Last Name")}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required />
                    </Form.Group>
                  </Col>
                </Row>
                <Row xs={1} md={2}>
                  <Col>
                    <Form.Group className="mb-4" controlId="email">
                      <Form.Control
                        type="email"
                        placeholder={t("Email Address")}
                        aria-label={t("Email Address")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-4" controlId="phone">

                      <PhoneInput
                        country={'us'}
                        onChange={phone => setPhone(phone)}
                        value={phone}
                        inputProps={{ id: "phone", required: "required" }}
                        inputClass='form-control'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row xs={1} md={2}>
                  <Col>
                    <Form.Group className="mb-4" controlId="website">
                      <Form.Control
                        type="text"
                        placeholder={t("Website")}
                        aria-label={t("Website")}
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        required />
                      <Form.Text>
                        {t("Website Example")}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-4" controlId="problem">
                      <Form.Select
                        aria-label={t("Select a Problem")}
                        value={problem}
                        onChange={(e) => setProblem(e.target.value)}
                        required>
                        <option value="" disabled>{t("Select a Problem")}</option>
                        <option value={t("Website URL Not Scanning")}>{t("Website URL Not Scanning")}</option>
                        <option value={t("Report Generation Failed")}>{t("Report Generation Failed")}</option>
                        <option value={t("Missing or Incomplete Accessibility Issues")}>{t("Missing or Incomplete Accessibility Issues")}</option>
                        <option value={t("False Positives in Accessibility Checks")}>{t("False Positives in Accessibility Checks")}</option>
                        <option value={t("Accessibility Checker is Crashing or Freezing")}>{t("Accessibility Checker is Crashing or Freezing")}</option>
                        <option value={t("Slow Performance During Scans")}>{t("Slow Performance During Scans")}</option>
                        <option value={t("Browser Compatibility Issues")}>{t("Browser Compatibility Issues")}</option>
                        <option value={t("Problem Downloading Report")}>{t("Problem Downloading Report")}</option>
                        <option value={t("Other Issue")}>{t("Other Issue")}</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formFile" className="mb-4">
                  <Form.Control
                    type="file"
                    accept="image/*,video/*"
                    aria-label={t("Upload File")}
                    onChange={(e) => handleFileInput(e)}
                  />
                  <Form.Text>
                    {t("File Input description")}
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-4 mb-lg-5" controlId="message">
                  <Form.Control
                    placeholder={t("Describe Problem")}
                    aria-label={t("Message")}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    as="textarea"
                    rows={3} />
                </Form.Group>
                <div className='text-center'>
                  <Button size='lg' type="submit">{t("Submit")}</Button>
                </div>
              </Form>
            </div>

          </Container>


        </div>
      </div>
      <Footer />
    </div>

  </>

}

export default ReportProblemPage;