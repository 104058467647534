import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import CombinedReducer from "../Reducers/CombinedReducer";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";
import { getCookie } from "../Services/CookieService";

const middleware = [thunk];

const rememberFlag = getCookie('remember');

if (rememberFlag !== "true") {
    /* window.addEventListener("beforeunload", () => {
        localStorage.removeItem("user");
    }); */
}

const initialState = {
    isLoading: false,
    toastMessage: null,
    websiteURL: null,
    scaningProcess: false,
    reScaningProcess: false,
    scaningProcessPer: 0,
    screenShot: null,
    isWidget: false,
    isPaid: false,
    standard: "WCAG 2.0, 2.1 & 2.2",
    levelFailCounts: null,
    levelFailCountsFixedByWidget: null,
    formSubmitFlag: false,
    lang: null
}

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['isLoading', 'toastMessage', 'websiteURL', 'scaningProcess', 'scaningProcessPer', 'reScaningProcess', 'screenShot', 'isWidget', 'isPaid', 'standard', 'levelFailCounts', 'levelFailCountsFixedByWidget']
}

const persistedReducer = persistReducer(persistConfig, CombinedReducer)



const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

let persistor = persistStore(store)

export { store, persistor };