import "../Assets/scss/base.scss";
const iOS = require('is-ios')

interface PropTypes {
    children: any
}

if(iOS){
    const body = document.querySelector("body");
    body?.classList.add("ios");
}

const MainTemplate = (prop: PropTypes) => {

    const { children } = prop;

    
    return (
        <>
            <div className="page-main">
                {children}
            </div>
        </>
    )
}

export default MainTemplate;